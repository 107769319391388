import {Form, useFetcher, useLocation} from "@remix-run/react"
import {useAnalytics} from "@shopify/hydrogen"
import clsx from "clsx"
import React, {useEffect, useRef} from "react"
import {createPortal} from "react-dom"
import {useDebouncedCallback} from "use-debounce"

import {IconArrow, IconClose, IconSearch} from "~/components/elements/Icon"
import {Input} from "~/components/elements/Input"
import {Link} from "~/components/elements/Link"
import {useToggle} from "~/hooks/use-toggle"
import type {
  FlattenedSearchQuery,
  SearchCollection,
  SearchProduct,
} from "~/types/search"
import {resourcePath} from "~/utilities/resource-path"

export const Search = () => {
  const location = useLocation()
  const fetcher = useFetcher<FlattenedSearchQuery>()
  const [isFocused, toggleIsFocused] = useToggle(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const {publish} = useAnalytics()

  const handleChange = useDebouncedCallback(
    async (event?: React.ChangeEvent<HTMLInputElement>) => {
      const query = event?.target.value
      fetcher.load(`/search?q=${query ?? ""}&s`)
      if (query) {
        publish("custom_search", {
          searchTerm: query,
        })
      }
    },
    500,
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      inputRef.current?.blur()
    }
  }

  useEffect(() => {
    inputRef.current?.blur()
  }, [location])

  return (
    <>
      <div
        className={clsx(
          "relative transition-all duration-200 w-full max-[862px]:px-[20px] max-[862px]:pb-1",
          isFocused ? "min-[862px]:max-w-[768px]" : "min-[862px]:max-w-[400px]",
        )}
      >
        <Form className="relative" action="/search" method="GET">
          <Input
            type="text"
            name="q"
            autoComplete="off"
            placeholder="Search"
            ref={inputRef}
            containerClassName="items-end"
            className="h-[44px] pr-[89px] w-full"
            onChange={handleChange}
            onFocus={toggleIsFocused}
            onBlur={toggleIsFocused}
            onKeyDown={handleKeyDown}
          />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="absolute top-0 right-[10px] h-full flex gap-[10px] items-center"
          >
            {!!inputRef.current?.value && (
              <>
                <IconClose
                  width="24"
                  height="24"
                  stroke={isFocused ? "cosmosgrey" : "ash"}
                  className="cursor-pointer"
                  onMouseDown={() => {
                    inputRef.current!.value = ""
                    handleChange()
                  }}
                />
                <div className="h-[calc(100%-2px)] w-0 border-r" />
              </>
            )}
            <button type="submit">
              <IconSearch
                width="24"
                height="24"
                stroke="cosmosgrey"
                className="cursor-pointer"
              />
            </button>
          </div>
        </Form>
        {isFocused && !!inputRef.current?.value && !!fetcher.data && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="z-10 absolute top-[56px] left-0 p-1 bg-white border border-ash rounded w-[calc(100%-40px)] md:w-[calc(min(100%,768px)+115px)] max-md:mx-[20px]"
          >
            {!fetcher.data.collections.length &&
            !fetcher.data.products.length ? (
              <span>No results found</span>
            ) : (
              <>
                {!!fetcher.data.collections.length && (
                  <>
                    <div className="flex flex-col">
                      {fetcher.data.collections.map((collection) => (
                        <SearchItemCollection
                          key={collection.id}
                          item={collection}
                        />
                      ))}
                    </div>
                    <hr className="w-full my-[10px]" />
                  </>
                )}
                {!!fetcher.data.products.length && (
                  <div className="flex flex-col">
                    {fetcher.data.products.map((product) => (
                      <SearchItemProduct
                        key={product.id}
                        item={product}
                        query={inputRef.current?.value}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {isFocused &&
        createPortal(
          <div className="fixed z-40 h-screen inset-0 bg-spacemist/80" />,
          document.body,
        )}
    </>
  )
}

type SearchItemCollectionProps = {
  item: SearchCollection
}

const SearchItemCollection = ({item}: SearchItemCollectionProps) => {
  return (
    <Link
      to={resourcePath(item)}
      className="flex gap-[5px] items-center px-[20px] h-[34px] text-cosmosgrey font-semibold hover:bg-haze"
    >
      <span className="leading-[26px] max-w-[calc(100%-30px)] whitespace-nowrap overflow-hidden text-ellipsis">
        {item.title}
      </span>
      <IconArrow width="24" height="24" />
    </Link>
  )
}

type SearchItemProductProps = {
  item: SearchProduct
  query?: string
}

const SearchItemProduct = ({item, query}: SearchItemProductProps) => {
  const {titleArr, matches} = getTitleMatch(item.title, query)

  const matchesArr = Array.from(matches ?? [])
  return (
    <Link
      to={resourcePath(item)}
      className="flex gap-[5px] items-center px-[20px] h-[34px] hover:bg-haze"
    >
      <span className="leading-[26px] whitespace-nowrap overflow-hidden text-ellipsis">
        {titleArr.map((str, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${item.id}_${str}_${index}`}>
            {str}
            {!!titleArr.length && titleArr.length - 1 !== index && (
              <span className="text-cosmosgrey font-semibold">
                {matchesArr.shift()}
              </span>
            )}
          </React.Fragment>
        ))}
      </span>
    </Link>
  )
}

const getTitleMatch = (title: string, query?: string) => {
  const regex = new RegExp(
    query?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&") ?? "",
    "ig",
  )

  return {
    titleArr: query ? title.split(regex) : [title],
    matches: query ? title.matchAll(regex) : undefined,
  }
}
