import "~/styles/tailwind.css"

import type {LinksFunction, LoaderFunctionArgs} from "@remix-run/cloudflare"
import {defer} from "@remix-run/cloudflare"
import type {ShouldRevalidateFunction} from "@remix-run/react"
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react"
import {captureRemixErrorBoundaryError, withSentry} from "@sentry/remix"
import type {SeoHandleFunction} from "@shopify/hydrogen"
import {Analytics, getShopAnalytics, useNonce} from "@shopify/hydrogen"

import {CartDrawer} from "~/components/global/CartDrawer"
import {Drawer} from "~/components/global/Drawer"
import {Footer} from "~/components/global/Footer"
import {Header} from "~/components/global/Header"
import {
  BodyTrackingScripts,
  HeadTrackingScripts,
} from "~/components/global/TrackingScripts"
import {LAYOUT_QUERY} from "~/graphql/queries/layout"
import bgImage404 from "~/images/error/404.jpg"
import bgImage500 from "~/images/error/500.jpg"
import logo from "~/images/logo.png"
import icon404 from "~/images/svgs/404.svg"
import icon500 from "~/images/svgs/500.svg"
import {isDevelopment} from "~/utilities/is-development"
import {parseMenu} from "~/utilities/parse-menu"

export type RootLoader = typeof loader

/**
 * This is important to avoid re-fetching root queries on sub-navigations
 */
export const shouldRevalidate: ShouldRevalidateFunction = ({
  formMethod,
  currentUrl,
  nextUrl,
}) => {
  // revalidate when a mutation is performed e.g add to cart, login...
  if (formMethod && formMethod !== "GET") {
    return true
  }

  // revalidate when manually revalidating via useRevalidator
  if (currentUrl.toString() === nextUrl.toString()) {
    return true
  }

  return false
}

const seo: SeoHandleFunction<typeof loader> = ({
  data: {shopInfo, env},
  pathname,
}) => ({
  title: "Enterprise software",
  titleTemplate: `%s | ${shopInfo.name}`,
  description: shopInfo.description,
  url: `${env.PUBLIC_SHOP_URL}${pathname.replace(/\/+$/, "")}`,
  jsonLd: [
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      legalName: shopInfo.name,
      name: shopInfo.name,
      url: env.PUBLIC_SHOP_URL,
      email: shopInfo.email?.value,
      logo: `${env.PUBLIC_SHOP_URL}${logo}`,
      contactPoint: {
        "@type": "ContactPoint",
        telephone: `+1-${shopInfo.phone}`,
        contactType: "customer support",
        contactOption: "TollFree",
        areaServed: ["US", "CA"],
        availableLanguage: ["English", "Spanish"],
      },
    },
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      name: shopInfo.name,
      url: env.PUBLIC_SHOP_URL,
    },
  ],
})

export const handle = {seo}

export const links: LinksFunction = () => {
  return [
    {
      rel: "preconnect",
      href: "https://cdn.shopify.com",
    },
    {
      rel: "preconnect",
      href: "https://shop.app",
    },
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap",
    },
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon/favicon.ico",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      type: "image/x-icon",
      href: "/favicon/apple-touch-icon-180.png",
    },
    {
      rel: "manifest",
      href: "/favicon/site.webmanifest",
    },
  ]
}

export const loader = async ({
  context: {
    storefront,
    cart,
    session,
    cloudflare: {env},
  },
}: LoaderFunctionArgs) => {
  const cartData = cart.get()

  const data = await storefront.query(LAYOUT_QUERY, {
    variables: {
      headerMenuHandle: "main-menu",
      footerMenuHandle: "footer",
    },
    cache: storefront.CacheCustom({
      mode: "public",
      maxAge: 300,
      staleWhileRevalidate: 3600,
    }),
  })

  const customPrefixes = {BLOG: "", CATALOG: "products"}

  const headerMenu = data?.headerMenu
    ? parseMenu(data.headerMenu, customPrefixes)
    : undefined

  const footerMenu = data?.footerMenu
    ? parseMenu(data.footerMenu, customPrefixes)
    : undefined

  return defer(
    {
      headerMenu,
      footerMenu,
      shopInfo: {
        ...data.shop,
        ...data.shopInfo,
      },
      cart: cartData,
      shop: getShopAnalytics({
        storefront,
        publicStorefrontId: env.PUBLIC_STOREFRONT_ID,
      }),
      consent: {
        checkoutDomain: env.PUBLIC_CHECKOUT_DOMAIN,
        storefrontAccessToken: env.PUBLIC_STOREFRONT_API_TOKEN,
      },
      env: {
        NODE_ENV: env.NODE_ENV,
        PUBLIC_SHOP_URL: env.PUBLIC_SHOP_URL,
        PUBLIC_GA_ID: env.PUBLIC_GA_ID,
        PUBLIC_KLAVIYO_ID: env.PUBLIC_KLAVIYO_ID,
        PUBLIC_GORGIAS_ID: env.PUBLIC_GORGIAS_ID,
        PUBLIC_HOTJAR_ID: env.PUBLIC_HOTJAR_ID,
        PUBLIC_SENTRY_DSN: env.PUBLIC_SENTRY_DSN,
        PUBLIC_SENTRY_ENV: env.PUBLIC_SENTRY_ENV,
        PUBLIC_SENTRY_RELEASE: env.PUBLIC_SENTRY_RELEASE,
      },
    },
    session.isPending
      ? {
          headers: {
            "Set-Cookie": await session.commit(),
          },
        }
      : undefined,
  )
}

type LayoutProps = {
  children?: React.ReactNode
}

export const Layout = ({children}: LayoutProps) => {
  const data = useRouteLoaderData<RootLoader>("root")
  const nonce = useNonce()

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta
          name="google-site-verification"
          content="mlWDza0WSSu5xGVfprPrzraXUDKXc9mVTNsUNqJ_2yY"
        />
        <Meta />
        <Links />
        <HeadTrackingScripts />
      </head>
      <body>
        {data ? (
          <Analytics.Provider
            cart={data.cart}
            shop={data.shop}
            consent={data.consent}
            // manual override fix for https://github.com/Shopify/hydrogen/issues/2475
            canTrack={() => true}
          >
            <Drawer.Provider>
              <div className="flex flex-col min-h-screen">
                <Header cart={data.cart} menu={data.headerMenu} />
                <main className="grow">{children}</main>
                <Footer shopInfo={data.shopInfo} menu={data.footerMenu} />
              </div>
              <CartDrawer cart={data.cart} />
            </Drawer.Provider>
            <BodyTrackingScripts />
          </Analytics.Provider>
        ) : (
          children
        )}
        <script
          id="global-env"
          nonce={nonce}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `
              window.globalEnv = ${JSON.stringify({
                NODE_ENV: data?.env.NODE_ENV,
                PUBLIC_SENTRY_DSN: data?.env.PUBLIC_SENTRY_DSN,
                PUBLIC_SENTRY_ENV: data?.env.PUBLIC_SENTRY_ENV,
                PUBLIC_SENTRY_RELEASE: data?.env.PUBLIC_SENTRY_RELEASE,
              })}
              window.GORGIASCHAT_CSP_NONCE = "${nonce}"
            `.trim(),
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  const data = useRouteLoaderData<RootLoader>("root")

  const isRouteError = isRouteErrorResponse(error)

  const is404 = isRouteError && error.status === 404

  if (isDevelopment(data?.env?.NODE_ENV)) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>
  }

  return (
    <div
      className="flex flex-col justify-center items-center gap-3 text-white bg-center bg-cover bg-no-repeat relative h-[calc(100vh-56px)] md:h-[calc(100vh-72px)]"
      style={{backgroundImage: `url(${is404 ? bgImage404 : bgImage500})`}}
    >
      <img src={is404 ? icon404 : icon500} alt={is404 ? "404" : "500"} />
      <div className="flex flex-col gap-1 text-center">
        <h1 className="text-subheading-2 text-white font-semibold">
          {is404 ? "Page not found" : "Something went wrong"}
        </h1>
        {!is404 && (
          <p>
            This page encountered an error. Refresh the page or try again later.
          </p>
        )}
      </div>
    </div>
  )
}

const Root = () => {
  return <Outlet />
}

export default withSentry(Root)
