import {Disclosure} from "@headlessui/react"
import {Await} from "@remix-run/react"
import clsx from "clsx"
import {Suspense, useRef, useState} from "react"
import {createPortal} from "react-dom"
import type {CartApiQueryFragment} from "storefrontapi.generated"

import {
  IconCart,
  IconChevron,
  IconClose,
  IconHamburger,
  IconLogo,
  IconUser,
} from "~/components/elements/Icon"
import {Link} from "~/components/elements/Link"
import {Search} from "~/components/global/Search"
import {useToggle} from "~/hooks/use-toggle"
import type {EnhancedMenu, EnhancedMenuItem} from "~/types/enhanced-menu"

type HeaderProps = {
  cart: Promise<CartApiQueryFragment | null>
  menu?: EnhancedMenu
}

export const Header = ({cart, menu}: HeaderProps) => {
  return (
    <>
      <MobileHeader cart={cart} menu={menu} />
      <DesktopHeader cart={cart} menu={menu} />
    </>
  )
}

const DesktopHeader = ({cart, menu}: HeaderProps) => {
  return (
    <header
      className="sticky top-0 z-50 font-bold text-cosmosgrey hidden min-[862px]:flex items-center h-[72px] px-2
      bg-white shadow-[0_8px_18px_rgba(0,0,0,0.05)]"
    >
      <Link className="flex items-center h-full" to="/">
        <IconLogo width={160} height={26} />
      </Link>
      <nav className="flex mx-2 h-full">
        {(menu?.items || []).map((item: EnhancedMenuItem) => (
          <DesktopMenuItem key={item.id} item={item} />
        ))}
      </nav>
      <div className="flex gap-1 w-full justify-end">
        <Search />
        <div className="flex gap-[7px] items-center">
          <Link to="/account">
            <IconUser className="hover:stroke-mist" stroke="cosmosgrey" />
          </Link>
          <Link to="/cart" className="relative">
            <IconCart className="hover:stroke-mist" stroke="cosmosgrey" />
            <Suspense>
              <Await resolve={cart}>
                {(cart) => (
                  <>
                    {!!cart?.totalQuantity && (
                      <div className="absolute top-0 left-[18px] flex items-center justify-center text-white text-[12px] bg-mist px-[5px] rounded-[10px]">
                        {cart.totalQuantity}
                      </div>
                    )}
                  </>
                )}
              </Await>
            </Suspense>
          </Link>
        </div>
      </div>
    </header>
  )
}

type DesktopMenuItemProps = {
  item: EnhancedMenuItem
}

// TODO: Add megamenu for deeply nested menu items
const DesktopMenuItem = ({item}: DesktopMenuItemProps) => {
  const [isOverlayActive, setIsOverlayActive] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const resetDropdown = () => {
    if (dropdownRef.current) {
      dropdownRef.current.style.display = "none"
      setTimeout(() => dropdownRef.current?.removeAttribute("style"), 0)
    }
    setIsOverlayActive(false)
  }

  if (!item.items.length) {
    return (
      <Link
        className="px-1 flex items-center text-color-cosmosgrey font-[700] hover:text-mist duration-100"
        key={item.id}
        to={item.to}
        prefetch="intent"
      >
        {item.title}
      </Link>
    )
  }

  return (
    <>
      <div
        className="flex relative group/nav-item"
        onMouseEnter={() => setIsOverlayActive(true)}
        onMouseLeave={() => setIsOverlayActive(false)}
      >
        <div
          className="px-1 flex items-center relative cursor-pointer text-color-cosmosgrey font-[700] hover:text-mist duration-100"
          key={item.id}
        >
          {item.title}
          <IconChevron width={20} height={20} direction={"down"} />
        </div>
        <div
          className="hidden flex-col w-max group-hover/nav-item:flex z-50
            absolute top-[72px] left-0 rounded-b overflow-hidden bg-white shadow-[0_30px_45px_rgba(48,52,58,0.4)]"
          ref={dropdownRef}
        >
          {item.items.map(({id, title, to}) => (
            <Link
              key={id}
              to={to}
              onClick={resetDropdown}
              className="flex items-center relative pl-[20px] pr-[50px] py-1 hover:bg-haze group/link"
            >
              {title}
              <IconChevron
                direction={"right"}
                width={22}
                height={22}
                className="absolute hidden group-hover/link:block group-active/link:text-nova right-[12px]"
              />
            </Link>
          ))}
        </div>
      </div>
      {isOverlayActive &&
        createPortal(
          <div className="fixed z-40 h-screen inset-0 bg-spacemist/80" />,
          document.body,
        )}
    </>
  )
}

const MobileHeader = ({cart, menu}: HeaderProps) => {
  const [isMenuOpen, toggleIsMenuOpen] = useToggle()

  return (
    <header
      className="relative z-50 flex min-[862px]:hidden px-[10px] justify-between items-center h-[56px]
      bg-white shadow-[0_8px_18px_rgba(0,0,0,0.05)]"
    >
      <button className="flex justify-center items-center w-[48px] h-full">
        {isMenuOpen ? (
          <IconClose stroke="cosmosgrey" size="sm" onClick={toggleIsMenuOpen} />
        ) : (
          <IconHamburger
            stroke="cosmosgrey"
            size="sm"
            onClick={toggleIsMenuOpen}
          />
        )}
      </button>
      <Link className="flex justify-center items-center h-full" to="/">
        <IconLogo width={135} />
      </Link>
      <Link
        to="/cart"
        className="relative flex justify-center items-center w-[48px] h-full"
      >
        <IconCart className="hover:stroke-mist" stroke="cosmosgrey" />
        <Suspense>
          <Await resolve={cart}>
            {(cart) => (
              <>
                {!!cart?.totalQuantity && (
                  <div
                    className="absolute top-[12px] right-[4px] flex items-center justify-center
                        text-white text-[12px] bg-mist w-[18px] h-[18px] rounded-[100%]"
                  >
                    {cart.totalQuantity}
                  </div>
                )}
              </>
            )}
          </Await>
        </Suspense>
      </Link>
      <div
        className={clsx(
          "absolute w-full top-[56px] left-0 bg-white pt-1",
          isMenuOpen || "hidden",
        )}
      >
        <>
          <Search />
          {(menu?.items || []).map((item: EnhancedMenuItem) => (
            <Disclosure key={item.id} as="div">
              {({open, close}: {open: boolean; close: () => void}) =>
                !item.items.length ? (
                  <Link
                    to={item.to}
                    onClick={toggleIsMenuOpen}
                    className="flex justify-between items-center w-full h-[55px] pl-[20px] pr-[14px] text-cosmosgrey font-semibold"
                  >
                    {item.title}
                  </Link>
                ) : (
                  <>
                    <Disclosure.Button className="flex justify-between items-center w-full h-[55px] pl-[20px] pr-[14px]">
                      <p className="text-cosmosgrey font-semibold">
                        {item.title}
                      </p>
                      <IconChevron
                        stroke="smoke"
                        direction={open ? "up" : "down"}
                        width={24}
                        height={24}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      as="nav"
                      className="flex flex-col"
                      unmount={false}
                    >
                      {item.items.map((subItem) => (
                        <Link
                          key={subItem.id}
                          to={subItem.to}
                          onClick={() => {
                            close()
                            toggleIsMenuOpen()
                          }}
                          className="flex items-center h-[55px] pl-[35px] pr-[14px] active:bg-haze"
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </Disclosure.Panel>
                  </>
                )
              }
            </Disclosure>
          ))}
        </>
      </div>
      {isMenuOpen &&
        createPortal(
          <div
            className="fixed z-40 h-screen inset-0 bg-spacemist/80"
            onClick={toggleIsMenuOpen}
            role="none"
          />,
          document.body,
        )}
    </header>
  )
}
