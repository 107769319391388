import {Disclosure} from "@headlessui/react"

import {IconChevron} from "~/components/elements/Icon"
import {Link} from "~/components/elements/Link"
import type {EnhancedMenu, EnhancedMenuItem} from "~/types/enhanced-menu"

type FooterMenuProps = {
  menu?: EnhancedMenu
}

export const FooterMenu = ({menu}: FooterMenuProps) => {
  return (
    <>
      <DesktopFooterMenu menu={menu} />
      <MobileFooterMenu menu={menu} />
    </>
  )
}

const DesktopFooterMenu = ({menu}: FooterMenuProps) => {
  return (
    <div className="hidden md:grid grid-cols-2 gap-3 col-span-5 order-2">
      {(menu?.items || []).map((item: EnhancedMenuItem) => (
        <div key={item.id} className="col-span-1">
          <h3 className="text-ash font-semibold mb-2">{item.title}</h3>
          {!!item?.items?.length && (
            <nav className="flex flex-col gap-1">
              {item.items.map((subItem) => (
                <Link
                  key={subItem.id}
                  to={subItem.to}
                  className="w-fit hover:text-white"
                >
                  {subItem.title}
                </Link>
              ))}
            </nav>
          )}
        </div>
      ))}
    </div>
  )
}

const MobileFooterMenu = ({menu}: FooterMenuProps) => {
  return (
    <div className="grid md:hidden col-span-1 order-1">
      {(menu?.items || []).map((item: EnhancedMenuItem) => (
        <Disclosure key={item.id} as="div">
          {({open}: {open: boolean}) => (
            <>
              <Disclosure.Button className="flex justify-between items-center w-full h-[55px] pl-[20px] pr-[14px]">
                <h3 className="text-ash font-semibold">{item.title}</h3>
                <IconChevron
                  stroke="ash"
                  direction={open ? "up" : "down"}
                  width={24}
                  height={24}
                />
              </Disclosure.Button>
              {!!item?.items?.length && (
                <Disclosure.Panel
                  as="nav"
                  className="flex flex-col"
                  unmount={false}
                >
                  {item.items.map((subItem) => (
                    <Link
                      key={subItem.id}
                      to={subItem.to}
                      className="flex items-center h-[55px] pl-[35px] pr-[14px] bg-cosmosgrey active:bg-spacemist"
                    >
                      {subItem.title}
                    </Link>
                  ))}
                </Disclosure.Panel>
              )}
            </>
          )}
        </Disclosure>
      ))}
    </div>
  )
}
