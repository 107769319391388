import {useRouteLoaderData} from "@remix-run/react"
import {Script} from "@shopify/hydrogen"

import {GoogleAnalytics} from "~/components/global/GoogleAnalytics"
import type {RootLoader} from "~/root"

export const HeadTrackingScripts = () => {
  const data = useRouteLoaderData<RootLoader>("root")

  if (!data) {
    return null
  }

  return (
    <>
      {/* ga script */}
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${data.env.PUBLIC_GA_ID}`}
      />

      {/* ga datalayer script */}
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${data.env.PUBLIC_GA_ID}', {
              // send_page_view: false,
              debug_mode: true
            });
        `.trim(),
        }}
      />

      <Script
        async
        waitForHydration
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${data.env.PUBLIC_KLAVIYO_ID}`}
      />

      {/* hotjar initialize global object */}
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            (function (h, o, t, j, a, r) {
              h.hj =
                h.hj ||
                function () {
                  (h.hj.q = h.hj.q || []).push(arguments);
                };
              h._hjSettings = { hjid: ${data.env.PUBLIC_HOTJAR_ID}, hjsv: 6 };
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        `.trim(),
        }}
      />

      {/* hotjar script */}
      <Script
        async
        src={`https://static.hotjar.com/c/hotjar-${data.env.PUBLIC_HOTJAR_ID}.js?sv=6`}
      />
    </>
  )
}

export const BodyTrackingScripts = () => {
  const data = useRouteLoaderData<RootLoader>("root")

  if (!data) {
    return null
  }

  return (
    <>
      {/* gorgias script */}
      <Script
        waitForHydration
        id="gorgias-chat-widget-install-v3"
        src={`https://config.gorgias.chat/bundle-loader/${data.env.PUBLIC_GORGIAS_ID}`}
      />

      <GoogleAnalytics />
    </>
  )
}
