import {FooterMenu} from "~/components/global/FooterMenu"
import type {EnhancedMenu} from "~/types/enhanced-menu"
import type {ShopInfo} from "~/types/shop-info"
import {formatPhone} from "~/utilities/format-phone"

type FooterProps = {
  shopInfo: ShopInfo
  menu?: EnhancedMenu
}

export const Footer = ({shopInfo, menu}: FooterProps) => {
  return (
    <section
      role="contentinfo"
      className="grid grid-cols-1 md:grid-cols-12 gap-[90px] md:gap-3 md:gap-x-3 md:gap-y-4 pt-1 md:pt-3 pb-2 md:px-2 bg-gradient-to-br from-[#1D2024] to-[#30343A] text-ash"
    >
      <div className="col-span-1 md:col-span-4 order-3 md:order-1 text-center md:text-left">
        <h3 className="text-titled text-white font-semibold mb-2">
          We provide industry solutions
        </h3>
        <p>
          The reliability and expertise your business needs to grow and
          optimize.
        </p>
      </div>
      <FooterMenu menu={menu} />
      <div className="col-span-1 md:col-span-3 order-4 text-center md:text-left">
        <h3 className="text-titled text-white font-semibold mb-1 md:mb-2">
          Call us, we&apos;re ready.
        </h3>

        <div className="flex flex-col">
          {shopInfo.phone?.value && (
            <p className="md:order-1">{formatPhone(shopInfo.phone.value)}</p>
          )}
          {shopInfo.startTime?.value &&
            shopInfo.endTime?.value &&
            shopInfo.timezone?.value && (
              <p className="md:order-2">
                {shopInfo.startTime.value} - {shopInfo.endTime.value}{" "}
                {shopInfo.timezone.value}
              </p>
            )}
          {shopInfo.email?.value && (
            <p className="md:mt-2 md:order-4">{shopInfo.email.value}</p>
          )}
          {shopInfo.address?.value && (
            <p className="mt-4 md:mt-2 flex flex-col md:order-3">
              {shopInfo.address.value.split("\n").map((line, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={idx}>{line}</span>
              ))}
            </p>
          )}
        </div>
      </div>
      <div className="col-span-1 md:col-span-12 order-last">
        {shopInfo.name && (
          <p className="text-center">
            Copyright &copy; {new Date().getFullYear()} {shopInfo.name}
          </p>
        )}
      </div>
    </section>
  )
}
