import {parseGid, useAnalytics} from "@shopify/hydrogen"
import type {
  CartLine,
  ComponentizableCartLine,
} from "@shopify/hydrogen/storefront-api-types"
import {useEffect} from "react"

import type {Product} from "~/types/product"

export const GoogleAnalytics = () => {
  const {subscribe, register} = useAnalytics()
  const {ready} = register("google-analytics")

  useEffect(() => {
    subscribe("page_viewed", () => {
      window.gtag?.("event", "page_view", {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: document.title,
      })
    })

    subscribe("product_viewed", (data) => {
      const product = data.products[0]
      if (product) {
        window.gtag?.("event", "view_item", {
          currency: data.shop?.currency ?? "USD",
          value: product.price,
          items: [
            {
              id: formatProductId(product.id, product.variantId),
              name: product.title,
              brand: product.vendor,
              price: product.price,
            } as GA4Item,
          ],
        })
      }
    })

    subscribe("collection_viewed", (data) => {
      const customData = data.customData as
        | {
            collection?: {title: string; products: Product[]}
          }
        | undefined

      const items =
        customData?.collection?.products.map<GA4Item>((product) => ({
          id: formatProductId(product.id, product.variants.edges[0].node.id),
          name: product.title,
          brand: product.vendor,
          price: product.variants.edges[0].node.price.amount,
        })) ?? []

      window.gtag?.("event", "view_item_list", {
        items,
        item_list_id: parseGid(data.collection.id).id,
        item_list_name: customData?.collection?.title,
      })
    })

    subscribe("cart_viewed", (data) => {
      window.gtag?.("event", "view_cart", {
        currency: data.shop?.currency ?? "USD",
        value: data.cart?.cost.subtotalAmount.amount ?? "0.00",
        items: data.cart?.lines.nodes.map(formatLineItem) ?? [],
      })
    })

    subscribe("search_viewed", (data) => {
      window.gtag?.("event", "search", {
        search_term: data.searchTerm,
      })
    })

    // predictive search tracking
    subscribe("custom_search", (data) => {
      if (data.searchTerm) {
        window.gtag?.("event", "search", {
          search_term: data.searchTerm,
        })
      }
    })

    subscribe("product_added_to_cart", (data) => {
      if (data.currentLine) {
        const item = formatLineItem(data.currentLine)
        if (item.quantity) {
          item.quantity -= data.prevLine?.quantity ?? 0
        }

        let value = parseFloat(data.currentLine.cost.totalAmount.amount)
        if (data.prevLine) {
          value -= parseFloat(data.prevLine.cost.totalAmount.amount)
        }

        window.gtag?.("event", "add_to_cart", {
          currency: data.shop?.currency ?? "USD",
          value: parseFloat(value.toFixed(2)),
          items: [item],
        })
      }
    })

    subscribe("product_removed_from_cart", (data) => {
      if (data.prevLine) {
        const item = formatLineItem(data.prevLine)
        if (item.quantity) {
          item.quantity -= data.currentLine?.quantity ?? 0
        }

        let value = parseFloat(data.prevLine.cost.totalAmount.amount)
        if (data.currentLine) {
          value -= parseFloat(data.currentLine.cost.totalAmount.amount)
        }

        window.gtag?.("event", "remove_from_cart", {
          currency: data.shop?.currency ?? "USD",
          value: parseFloat(value.toFixed(2)),
          items: [item],
        })
      }
    })

    ready()
  }, [ready, subscribe])

  return null
}

// Shopify checkout GA4 uses id format shopify_US_productId_variantId
const formatProductId = (productId: string, variantId: string) => {
  return `shopify_US_${parseGid(productId).id}_${parseGid(variantId).id}`
}

const formatLineItem = (item: CartLine | ComponentizableCartLine): GA4Item => {
  return {
    id: formatProductId(item.merchandise.product.id, item.merchandise.id),
    name: item.merchandise.product.title,
    brand: item.merchandise.product.vendor,
    price: item.cost.amountPerQuantity.amount,
    quantity: item.quantity,
  }
}

// Using the same fields Shopify checkout GA4 uses for item. GA4 renames fields on their end
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=12567473332162386439-NC&client_type=gtag#view_item_item
type GA4Item = {
  id: string
  name: string
  price: string
  brand?: string
  category?: string
  quantity?: number
}
